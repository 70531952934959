import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export const isEmpty = (value: any) => {
    if (value === null || value === undefined) {
        return true
    }

    if (typeof value === "boolean") {
        return !value
    }

    if (typeof value === "number") {
        return value === 0
    }

    if (typeof value === "string") {
        return value.trim() === "" || value === "0"
    }

    if (Array.isArray(value)) {
        return value.length === 0
    }

    if (typeof value === "object") {
        return Object.keys(value).length === 0
    }

    return false
}
